<template>
  <Container space-before space-after>
    <div class="email-signup flex flex-col sm:flex-row">
      <Container v-if="videoId" class="sm:flex-auto">
        <WistiaEmbedPlayer :wistia-id="videoId" />
      </Container>
      <Container v-if="image" class="sm:flex-auto">
        <img
          :src="image"
          :alt="imageAltText"
          class="default-image object-scale down h-50 rounded w-full object-cover"
          loading="lazy"
        />
      </Container>
      <Container class="sm:flex-auto">
        <Headline :level="2" :size="3" class="em-title"> {{ title }}</Headline>
        <NewsletterBar
          :label="label"
          :placeholder="placeholder"
          :submit-text="submitText"
          :success-text="successText"
          :klaviyo-list-id="klaviyoListId"
          class="mb-6"
        />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="email-text" v-html="text" />
      </Container>
    </div>
  </Container>
</template>

<script>
  import NewsletterBar from "./newsletter_bar"
  import WistiaEmbedPlayer from "~/components/WistiaEmbed/player"
  import Headline from "~/components/Headline"

  export default {
    components: { WistiaEmbedPlayer, Headline, NewsletterBar },
    props: {
      videoId: {
        type: String,
        default: "",
      },
      title: {
        type: String,
        default: "",
      },
      text: {
        type: String,
        default: "",
      },
      submitText: {
        type: String,
        default: "Subscribe",
      },
      successText: {
        type: String,
        default: "Thanks! Your email has been submitted.",
      },
      label: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      klaviyoListId: {
        type: String,
        default: "",
      },
      image: {
        type: String,
        default: null,
      },
      imageAltText: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .email-signup {
    border-radius: $border-radius;
    align-items: center;
    box-shadow: $shadow;
    background: white;
  }
  .em-title {
    margin-bottom: $space-s;
  }
  .email-text {
    &:deep(p) {
      color: $gray-text;
    }
    &:deep(ul) {
      padding-left: $base-spacing * 4;
    }
  }
</style>
