<template>
  <div>
    <form
      v-if="showForm"
      autocomplete="on"
      class="newsletter"
      @submit.prevent.stop="submitEmail"
    >
      <label class="nl-input-wrap">
        <span class="nl-input-label"> {{ label }} </span>
        <Input
          v-model.lazy.trim="email"
          type="email"
          autocomplete="email"
          :placeholder="placeholder"
          class="nl-input"
          size="large"
          required
        />
      </label>
      <Button class="nl-button">{{ submitText }}</Button>
    </form>
    <Alert v-else type="success" :title="successText" size="normal" />
    <Alert
      v-if="failureMessage"
      type="error"
      :title="failureMessage"
      size="normal"
    />
  </div>
</template>

<script>
  import { mapActions } from "pinia"
  import Alert from "~/components/Alert"
  import Input from "~/components/Input"
  import Button from "~/components/Button"
  import loadExternalScript from "~/mixins/loadExternalScript"
  import { useNotificationStore } from "~/pinia/notifications"
  export default {
    components: { Alert, Input, Button },
    mixins: [loadExternalScript],

    props: {
      label: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      submitText: {
        type: String,
        default: "Subscribe",
      },
      successText: {
        type: String,
        default: "Thanks! Your email has been submitted.",
      },
      klaviyoListId: {
        type: String,
        default: "",
      },
    },

    data() {
      return {
        email: "",
        showForm: true,
        failureMessage: "",
      }
    },
    methods: {
      ...mapActions(useNotificationStore, ["announce"]),

      async submitEmail() {
        if (await this.klaviyoRequest()) {
          this.email = ""
          this.showForm = false
          this.announce(this.successText)
        } else {
          this.failureMessage =
            "There was an error processing your request, please try again later."
        }
      },
      async klaviyoRequest() {
        try {
          await $fetch(
            `https://a.klaviyo.com/client/subscriptions/?company_id=${this.$config.public.KLAVIYO_PUBLIC_KEY}`,
            {
              method: "POST",
              body: {
                data: {
                  type: "subscription",
                  attributes: {
                    list_id: this.klaviyoListId,
                    email: this.email,
                  },
                },
              },
              headers: {
                revision: "2023-02-22",
                "content-type": "application/json",
              },
            },
          )
          return true
        } catch (error) {
          console.error(error)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .newsletter {
    display: flex;
    align-items: flex-end;
  }

  .nl-input-wrap {
    flex: 1;
  }
  .nl-input.nl-input.nl-input.nl-input {
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .nl-input-label {
    font-weight: bold;
    display: block;
    margin-bottom: $base-spacing * 2;
  }
  .nl-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>
