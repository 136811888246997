<template>
  <EmailSignup
    :title="title"
    :placeholder="inputPlaceholder"
    :submit-text="submitText"
    :success-text="successText"
    :label="inputLabel"
    :image="picture"
    :text="text"
    :video-id="videoId"
    :klaviyo-list-id="klaviyoListId"
    :image-alt-text="imageAltText"
  />
</template>

<script lang="ts" setup>
  const props = defineProps<{
    element: AlchemyElement
  }>()

  const { getValue, getRichtext, getIngredient } = useAlchemy()
  const title = getValue(props.element, "title")
  const text = getRichtext(props.element, "text")
  const picture = getValue(props.element, "picture")
  const videoId = getValue(props.element, "video_id")
  const inputPlaceholder = getValue(props.element, "input_placeholder")
  const inputLabel = getValue(props.element, "input_label")
  const submitText = getValue(props.element, "submit_text") || "Subscribe"
  const successText = getValue(props.element, "success_text")
  const klaviyoListId = getValue(props.element, "klaviyo_list_id")
  const imageAltText = getIngredient<AlchemyIngredientPicture>(
    props.element,
    "picture",
  )?.altText
</script>
